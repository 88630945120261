import React, {useState,useEffect} from 'react';
import { connect } from "react-redux";
import Select from 'react-select';
import StickyBox from "react-sticky-box";
import SearchFilter from './searchFilter';
import SearchList from './searchList';

 import {get_all_category_call_back_req} from "../../../../redux-services/actions/action.test/action.function.js"



function AllTests(props) {
	const [search, setSearch] = useState(null);
	const [category,setCategory] = useState([]);
	const [searchCategory, setSearchCategory] = useState([]);
	const [popularTestState, setPopularTestState] = useState(false);
	const getAllCategoryApi = async() =>{
		await props.get_all_category(null,(error,success)=>{
			if(error){
			  
			  console.log("error",error);
			}    
			if(success.status == 200 ){
			  if((success && success?.response.length > 0)){
				console.log("category list", success?.response);
				setCategory(success?.response)
				
			   }
			   if((success && success?.response.length < 0)){
		   
				 setCategory([]);
		  
			   }
			}
		  })
	}
	useEffect(() => {
	// 	const { get_all_test } =props;
	// 	get_all_test();
	// 	 const all_test = props.all_test;
	//   console.log("all tests values=====",all_test);
	 getAllCategoryApi();
},[]);
	
		const options = [
			{ value: 'Select', label: 'Select' },
			{ value: 'Popular', label: 'Popular' },
			{ value: 'Rating', label: 'Rating' },
			// { value: 'Lastest', label: 'Lastest' },
			// { value: 'Free', label: 'Free' },
		]

		const setSearchFnc = (data) =>{
			setSearch(data)
			console.log("category list", search);
		}
		//  console.log("category list", category);
		const setSearchCategoryFnc = (data) =>{
			setSearchCategory(data);
			console.log("category list", searchCategory);

		}

		const handleFilter = (event) =>{
			if(event.value == 'Popular'){
				setPopularTestStateFnc(true);
			} else {
				setPopularTestStateFnc(false);
			}

		}
		const setPopularTestStateFnc = (data) =>{
			setPopularTestState(data);
			
		}
		console.log("poupar test set state",popularTestState);
		return (
			<div>
				<div className="breadcrumb-bar">
					<div className="container-fluid">
						<div className="row align-items-center">
							<div className="col-md-8 col-12">
								<nav aria-label="breadcrumb" className="page-breadcrumb">
									<ol className="breadcrumb">
										<li className="breadcrumb-item"><a href="/home">Home</a></li>
										<li className="breadcrumb-item active" aria-current="page">All Tests</li>
									</ol>
								</nav>
								<h2 className="breadcrumb-title">{(props.all_test && (props.all_test?.response.length > 0))? props.all_test?.response.length: 0} matches found </h2>
							</div>
							<div className="col-md-4 col-12 d-md-block d-none">
								<div className="sort-by">
									<span className="sort-title">Sort by</span>
									<span className="sortby-fliter">
										<Select 
										options={options} 
										onChange={(event) => {
                              handleFilter(event);
                            }}
										/>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="content">
					<div className="container-fluid">

						<div className="row">
							<div className="col-md-12 col-lg-4 col-xl-3">
								<StickyBox offsetTop={20} offsetBottom={20}>
									<SearchFilter {...props} searchFilter={setSearchFnc} searchCategoryFilter={setSearchCategoryFnc} searchCategoryData={category}/>
								</StickyBox>
							</div>

							<div className="col-md-12 col-lg-8 col-xl-9">
								<SearchList {...props} searchFilterData={search} searchFilterCategoryData={searchCategory} popularTestFilter={popularTestState}/>
								{/* <div className="load-more text-center">
									<a href="#0" className="btn btn-primary btn-sm">Load More</a>
								</div> */}
							</div>
						</div>

					</div>

				</div>
			</div>
		);
	
}

const mapDispatchToProps = (dispatch) => {
	return {
		get_all_category: (data,callback) =>dispatch(get_all_category_call_back_req(data,callback)),
        
	};
  };
  const mapStateToProps = (state) => {
	return {
	  status: state.user.status,
	  error: state.user.error,
      all_test: state.test.all_test,
      all_popular_test: state.test.all_popular_test,
	  user: state.user.user,
	  isLoggedIn: state.user.isLoggedIn,
	  requesting: state.user.requesting,
  
  
	};
  };
  export default connect(mapStateToProps, mapDispatchToProps)(AllTests);